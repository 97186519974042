import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import reduce from 'lodash-es/reduce';

import { MessageAction } from '../reducers/message/message.action';

/**
 * Strategy to use when converting an object to search params.
 * - COMMA_SEPARATED: Convert arrays to a comma-separated string.
 * Example: { key: ['value1', 'value2'] } => key=value1,value2
 * - MULTIPLE_PARAMS: Convert arrays to multiple parameters with the same key.
 * Example: { key: ['value1', 'value2'] } => key[0]=value1&key[1]=value2
 */
export enum ArrayStrategy {
  COMMA_SEPARATED = 'comma_separated',
  MULTIPLE_PARAMS = 'multiple_params',
}

export const isJsObject = (o: any): boolean =>
  o !== null && (typeof o === 'function' || typeof o === 'object') && !Array.isArray(o);

export const objectToSearchParams = (rawParams: { [key: string]: any }, arrayStrategy?: ArrayStrategy) => {
  if (!rawParams) {
    return {};
  }

  return camelizeObjectKeys(rawParams, arrayStrategy);
};

/**
 * Converts an object to a parametrised string.
 * @param object
 * @returns {string}
 */
export const camelizeObjectKeys = (object: any, arrayStrategy: ArrayStrategy): any =>
  reduce(
    object,
    (acc, value, key) => {
      if (value === undefined || value === null) {
        return acc;
      }

      key = camelCaseToSnakeCase(key);

      if (Array.isArray(value) && value.length && arrayStrategy) {
        switch (arrayStrategy) {
          case ArrayStrategy.COMMA_SEPARATED:
            acc[key] = value.join(',');
            break;
          case ArrayStrategy.MULTIPLE_PARAMS:
            value.forEach((v, i) => {
              acc[`${key}[${i}]`] = v;
            });
            break;
        }
      } else if (value) {
        acc[key] = isJsObject(value) ? camelizeObjectKeys(value, arrayStrategy) : value;
      }

      return acc;
    },
    {},
  );

export const camelCaseToSnakeCase = (key: string): string =>
  key
    .replace(/([A-Z])/g, '_$1')
    .replace(/^_/, '')
    .toLowerCase();

export const showAttachmentUploadError = (
  translateService: TranslateService,
  store: Store,
  status: number,
  filter: any,
): void => {
  const message = extractFilterMessage(filter);
  if (message !== null) {
    showFilterMessage(message, store, translateService);
    return;
  }

  switch (status) {
    case 422:
      store.dispatch(MessageAction.error(translateService.instant('You are not allowed to upload files of this type')));
      break;
    case 400:
      store.dispatch(
        MessageAction.error(
          translateService.instant('The file you uploaded may be dangerous and cannot be uploaded to Shiftbase'),
        ),
      );
      break;
    default:
      store.dispatch(MessageAction.error(translateService.instant('The file is too large')));
  }
};

export const arrayToQueryParams = (key: string, array: string[]): string => {
  key = camelCaseToSnakeCase(key);

  // Specific format required by the API
  // key_in_snake_case[]=value1&key_in_snake_case[]=value2
  const keyPrefix = key + '[]=';
  return keyPrefix + array.join('&' + keyPrefix);
};

const extractFilterMessage = (filter: any): string | null => {
  if (typeof filter !== 'string') {
    return null;
  }

  let parsedFilter;
  try {
    parsedFilter = JSON.parse(filter);
  } catch (e) {
    return null;
  }

  if (!Array.isArray(parsedFilter?.meta?.feedback)) {
    return null;
  }

  // For every message in the feedback, match them to a known error.
  for (let i = 0; i < parsedFilter.meta.feedback.length; i++) {
    const message = parsedFilter.meta.feedback[i];

    if (message === 'File type is banned') {
      return message;
    }
  }
  return null;
};

const showFilterMessage = (message: string, store: Store, translateService: TranslateService): void => {
  switch (message) {
    case 'File type is banned':
      store.dispatch(MessageAction.error(translateService.instant('You are not allowed to upload files of this type')));
  }
};
